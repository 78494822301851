<template>
	<div class="cHeader">
		<div class="cHeader_con">
			<div class="c_logo">
				<img src="@/views/images/c_logo.png" alt="" @click="goHome" />
			</div>
			<div class="web_info">
				<!--登录信息-->
				<div class="login_info">
					<div class="nologin">
						<el-dropdown @command="logout">
							<div class="logined_info el-dropdown-link">
								<img :src="userinfo.stu_headimg" alt="" v-if="userinfo.stu_headimg" />
								<span v-else>
									<img v-if="userinfo.stu_sex == '女'" src="@/views/images/nv.png" alt=""  />
									<img v-else src="@/views/images/nan.png" alt="" />
								</span>
								<span>{{userinfo.stu_name}}</span>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="a">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<!-- 登录后 -->
				<div class="after_login">
					<div class="learning_box"  @click="toLCenter">
						<img src="@/views/images/c_learning_icon.png" alt="" />
						<span>学习中心</span>
					</div>
					<div class="learning_box"  @click="toCourseList">
						<img src="@/views/images/c_sign_icon.png" alt="" />
						<span>立即报名</span>
					</div>
				</div>
			</div>
		</div>
		<div class="web_nav">
			<ul>
				<li @click="goHome">
					<div class="liBox">首页</div>
				</li>
				<li @click="toCourseList">
					<div class="liBox">选课中心</div>
				</li>
				<li @click="tospecialList">
					<div class="liBox">专题培训</div>
				</li>
				<li v-for="(item,index) in menuList" :key="index" @click="menuClick(item)">
					<div class="liBox">{{item.col_name}}</div>
				</li>
			</ul>
		</div>
		<!-- 右侧浮窗 -->
		<offside :data="isLogin"></offside>
		<!-- 成绩弹窗 -->
		<el-dialog :visible.sync="scoreVisible" 
			width="350px" 
			custom-class="scoreDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<scorebox :examData="examData" @close-score="closeScore" @once-more="onceMore" @answer-sheet="answerSheet"></scorebox>
		</el-dialog>
		<!-- 答卷弹窗 -->
		<el-dialog :visible.sync="answerVisible"
			width="900px" 
			custom-class="answerDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<answer-sheet :answerloading="answerloading" :couData="couData" :studentExam="studentExam" :answerList="answerList" @goback-list="closeScore"></answer-sheet>
		</el-dialog>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';  
	import offside from "/src/components/offside";//右侧浮窗
	import scorebox from "/src/components/scorebox";//交卷后弹窗
	import answerSheet from "/src/components/answerSheet";//答卷弹窗
	import { Loading } from 'element-ui';
	export default {
		components:{
			offside,
			scorebox,
			answerSheet
		},
		data() {
			return {
				loading:false,
				isLogin: false, //是否登录
				userinfo:{
					stu_name:"",
					stu_headimg:""
				},//登录信息
				scoreVisible:false,
				examData:{},//提交后信息
				stex_id:"",//提交后答卷id
				answerVisible:false,//答卷弹窗
				answerloading:true,//答卷遮罩
				studentExam:{},//答题信息
				answerList:[],//答卷列表
				couData:{},//课程信息
				menuList:[],//栏目列表
			}
		},
		created() {
			if(window.sessionStorage.getItem('userinfo')){
				this.isLogin = true;
				this.userinfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo')))
			}
			//获取栏目数据
			this.getMenu();
		},
		methods: {
			//获取栏目数据
			getMenu(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/column.html", {
					page:1,
					limit:999
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.menuList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//点击菜单
			menuClick(item){
				if(item.col_name == "精品课程"){
					this.$router.push({
						path: "/WcourseList"
					});
				}else{
					this.$router.push({
						path: "/newsList",
						query: {
							col_id: this.PublicJs.Encrypt(item.col_id),
							col_name:this.PublicJs.Encrypt(item.col_name),
						}
					});
				}
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(item.col_id))
			},
			//跳转专题培训
			tospecialList(){
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("special"))
				this.$router.push({
					path: "/specialList"
				});
			},
			//跳转首页
			goHome(){
				this.$router.push({
					path: "/"
				});
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//跳转学习中心
			toLCenter(){
				if(this.PublicJs.isExam){
					this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						//交卷
						this.handPapers('LearningCenter')
					}).catch(() => {
					           
					});
				}else{
					this.$router.push({
						path: "/LearningCenter"
					});
				}
			},
			//跳转课程列表
			toCourseList(){
				if(this.PublicJs.isExam){
					this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						//交卷
						this.handPapers('courseList')
					}).catch(() => {
					           
					});
				}else{
					Bus.$emit('busIsHome', false);//是否为省平台
					window.sessionStorage.setItem('isHome',false)
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("course"))
					this.$router.push({
						path: "/courseList"
					});
				}
			},
			//交卷
			handPapers(type){
				var that = this;
				var subjectData = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem("subjectData")));
				
				var answerdb = {}
				for(var i=0;i<subjectData.length;i++){
					answerdb[subjectData[i].que_id] = subjectData[i].choseAnswer;
				}
				this.loading=true;
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/submitExam.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.PublicJs.Encrypt(subjectData[0].que_course_id),
					answerdb:answerdb
				}, {
					emulateJSON: true
				}).then(response => {
					this.loading=false;
					this.PublicJs.isExam = false;
					if(response.data.code == 1){
						this.scoreVisible = true;
						this.examData = response.data.data.examData;
						this.stex_id = response.data.data.stex_id;
						window.sessionStorage.removeItem("subjectData")
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
						setTimeout(()=>{
							this.$router.push({
								path:"/"+type
							})
						},3000)
					};
					setTimeout(function(){
						for (var i = 1; i < 99999; i++) {
							clearTimeout(i); //去除延时器
						}
					},4000);
				}, response => {
					this.$message({
						message: "您的账号在别处登录，请重新登录",
						type: 'warning',
						showClose: true
					});
					window.sessionStorage.removeItem('user_id')
					window.sessionStorage.removeItem('token')
					window.sessionStorage.removeItem('userinfo')
					this.$router.go(0)
				});
			},
			//关闭按钮
			closeScore(){
				this.$router.go(0)
			},
			//再来一次
			onceMore(){
				var that = this;
				if(this.examData.shengyu > 0){
					this.scoreVisible = false;
					this.$router.go(0)
				}else{
					this.$message({
						message: "您已没有考试次数",
						type: 'warning',
						showClose: true
					});
					setTimeout(function(){
						that.$router.go(0)
					},500);
				}
			},
			//查看答卷
			answerSheet(){
				this.scoreVisible = false;
				this.answerVisible = true;
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/getExamData", {
					token:window.sessionStorage.getItem('token'),
					stex_id:this.PublicJs.Encrypt(this.stex_id)
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.answerloading = false;
						this.answerList = response.data.data.data.data;
						this.studentExam = response.data.data.studentExam;
						this.couData = response.data.data.couData;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//退出登录
			logout(command){
				if(command == "a"){
					if(this.PublicJs.isExam){
						this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
						  confirmButtonText: '确定',
						  cancelButtonText: '取消',
						  type: 'warning'
						}).then(() => {
							//交卷
							this.handPapers()
						}).catch(() => {
						           
						});
					}else{
						this.$http.post(this.PublicJs.publicPath + "/api/student.Info/logout.html", {
							token:window.sessionStorage.getItem('token')
						}, {
							emulateJSON: true
						}).then(response => {
							if(response.data.code == 1){
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								this.$router.push({
									path: "/"
								});
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							if(response.status == 401){
								var that = this;
								this.$message({
									message: "您的账号在别处登录，请重新登录",
									type: 'warning',
									showClose: true
								});
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								setTimeout(function(){
									that.$router.go(0)
								},2000); 
								
							}
						});
					}
				}
			}
		},
	}
</script>

<style scoped="scoped">
	.cHeader{
		width: 100%;
		height: 184px;
		background-image: url(../images/c_h_bg.png);
		background-size: cover;
		min-width: 1200px;
	}
	.cHeader_con{
		width: 1200px;
		margin: 0 auto;
		position: relative;
		height: 124px;
	}
	.c_logo{
		height: 124px;
		font-size: 0;
		line-height: 124px;
		float: left;
	}
	.c_logo img{
		vertical-align: middle;
		width: 243px;
		height: 40px;
	}
	.web_info{
		float: right;
		margin-top: 30px;
	}
	.web_info::after {
		display: block;
		content: "";
		clear: both;
	}
	.login_info {
		display: inline-block;
		vertical-align: middle;
		margin-left: 39px;
		margin-top: 12px;
		float: right;
	}
	.nologin {
		cursor: pointer;
	}
	.login_info img {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		width: 38px;
		height: 38px;
		border-radius: 19px;
	}
	.login_info span {
		color: #FFF;
		font-size: 16px;
		font-family: "微软雅黑";
		display: inline-block;
		vertical-align: middle;
		font-weight: 400;
	}
	.after_login {
		float: right;
		font-size: 0px;
	}
	.learning_box {
		display: inline-block;
		line-height: 60px;
		margin-left: 45px;
		cursor: pointer;
	}
	
	.learning_box img {
		width: 28px;
		height: 24px;
		vertical-align: middle;
		margin-right: 12px;
	}
	
	.learning_box span {
		color: #FFF;
		font-size: 14px;
		vertical-align: middle;
	}
	
	/*导航*/
	.web_nav {
		width: 100%;
		height: 60px;
		background: #4d6ef2;
		min-width: 1200px;
	}
	
	.web_nav ul {
		width: 1200px;
		margin: 0 auto;
		height: 100%;
		font-size: 0px;
	}
	
	.web_nav ul li {
		width: 160px;
		display: inline-block;
	
	}
	
	.web_nav ul li .liBox {
		width: 110px;
		margin: 0 auto;
		text-align: center;
		line-height: 60px;
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
		font-family: "微软雅黑";
		cursor: pointer;
	}
	
	.web_nav ul li.active .liBox {
		background: #74a2ff;
	}
</style>
