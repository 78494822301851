<template>
	<div class="LearningCenter">
		<!--头部-->
		<headers></headers>
		<!-- 中间部分 -->
		<div class="LC_content">
			<div class="LC_container">
				<div class="LC_left">
					<div class="studentCon">
						<img :src="userinfo.stu_headimg" alt="" v-if="userinfo.stu_headimg" class="studentImg" />
						<span v-else>
							<img v-if="userinfo.stu_sex == '女'" src="@/views/images/nv.png" alt="" class="studentImg" />
							<img v-else src="@/views/images/nan.png" alt="" class="studentImg" />
						</span>
						<div class="studentName">{{userinfo.stu_name}}</div>
						<div class="websiteName">贵州电子信息职业技术学院继续教育平台</div>
						<div class="studentBtn" :class="{ active: btnChose=='sign' }"  @click="toCourseList">立即报名</div>
						<div class="studentBtn" :class="{ active: btnChose=='records' }"  @click="goLearningRecords">学习记录</div>
					</div>
					<div class="C_menu">
						<div class="C_menu_item" :class="{ active: menuChose=='course' }"  @click="gotoMenu('course')">
							<img src="@/views/images/course_icon.png" />
							<span>课程</span>
						</div>
						<div class="C_menu_item" :class="{ active: menuChose=='practice' }"  @click="gotoMenu('practice')">
							<img src="@/views/images/practice_icon.png" />
							<span>练习</span>
						</div>
						<div class="C_menu_item" :class="{ active: menuChose=='exam' }"  @click="gotoMenu('exam')">
							<img src="@/views/images/exam_icon.png" />
							<span>考试</span>
						</div>
						<div class="C_menu_item" :class="{ active: menuChose=='certificate' }" @click="gotoMenu('certificate')">
							<img src="@/views/images/certificate_icon.png" />
							<span>证书</span>
						</div>
						<div class="C_menu_item" :class="{ active: menuChose=='message' }"  @click="gotoMenu('message')">
							<img src="@/views/images/message_icon.png" />
							<span>消息</span>
						</div>
						<div class="C_menu_item" :class="{ active: menuChose=='order' }"  @click="gotoMenu('order')">
							<img src="@/views/images/order_icon.png" />
							<span>订单</span>
						</div>
						<div class="C_menu_item" :class="{ active: menuChose=='setup' }"  @click="gotoMenu('setup')">
							<img src="@/views/images/setup_icon.png" />
							<span>设置</span>
						</div>
					</div>
				</div>
				<div class="LC_right" :class="{isRecords:isRecords}">
					<router-view></router-view>
				</div>
			</div>
		</div>
		<!--尾部-->
		<footers></footers>
		<!-- 成绩弹窗 -->
		<el-dialog :visible.sync="scoreVisible" 
			width="350px" 
			custom-class="scoreDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<scorebox :examData="examData" @close-score="closeScore" @once-more="onceMore" @answer-sheet="answerSheet"></scorebox>
		</el-dialog>
		<!-- 答卷弹窗 -->
		<el-dialog :visible.sync="answerVisible"
			width="900px" 
			custom-class="answerDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<answer-sheet :couData="couData" :studentExam="studentExam" :answerList="answerList" @goback-list="closeScore"></answer-sheet>
		</el-dialog>
	</div>
</template>

<script>
	import headers from "/src/views/LearningCenter/cHeader";//头部
	import footers from "../footer";//尾部
	import Bus from '/src/views/js/bus.js'; 
	import scorebox from "/src/components/scorebox";//交卷后弹窗
	import answerSheet from "/src/components/answerSheet";//答卷弹窗
	import { Loading } from 'element-ui';
	export default {
		components: {
			headers,
			footers,
			scorebox,
			answerSheet
		},
		data() {
			return {
				loading:false,
				menuChose:"course",//选中菜单
				btnChose:"sign",//选中按钮
				userinfo:{
					stu_name:"",
					stu_headimg:""
				},//登录信息
				scoreVisible:false,
				examData:{},//提交后信息
				stex_id:"",//提交后答卷id
				answerVisible:false,//答卷弹窗
				studentExam:{},//答题信息
				answerList:[],//答卷列表
				couData:{},//课程信息
				isRecords:false,//是否学习记录
				cityName:"",//城市名称
			}
		},
		methods: {
			//跳转学习记录
			goLearningRecords(){
				if(this.PublicJs.isExam){
					this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						//交卷
						this.handPapers('LearningRecords')
					}).catch(() => {
					           
					});
				}else{
					this.isRecords = true;
					this.btnChose = "records";
					this.menuChose = "";
					this.$router.push({
						path: "/LearningRecords"
					});
				}
			},
			//跳转对应菜单
			gotoMenu(type){
				if(this.PublicJs.isExam){
					this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						//交卷
						this.handPapers(type)
					}).catch(() => {
					           
					});
				}else{
					this.isRecords = false;
					this.btnChose = "sign";
					this.menuChose = type;
					this.$router.push({
						path: "/" + type
					});
				}
			},
			//立即报名
			toCourseList(){
				if(this.PublicJs.isExam){
					this.$confirm('您当前正在考试，离开即交卷，确定要离开?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						//交卷
						this.handPapers('courseList')
					}).catch(() => {
					           
					});
				}else{
					this.$router.push({
						path: "/courseList"
					});
				}
			},
			//交卷
			handPapers(type){
				var that = this;
				var subjectData = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem("subjectData")));
				var answerdb = {}
				for(var i=0;i<subjectData.length;i++){
					answerdb[subjectData[i].que_id] = subjectData[i].choseAnswer;
				}
				this.loading=true;
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/submitExam.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.PublicJs.Encrypt(subjectData[0].que_course_id),
					answerdb:answerdb
				}, {
					emulateJSON: true
				}).then(response => {
					this.loading=false;
					this.PublicJs.isExam = false;
					if(response.data.code == 1){
						this.scoreVisible = true;
						this.examData = response.data.data.examData;
						this.stex_id = response.data.data.stex_id;
						this.answerList = response.data.data.records;
						this.studentExam = response.data.data.examData;
						this.couData = response.data.data.courseInfo;
						window.sessionStorage.removeItem("subjectData")
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
						if(type=="courseList"){
							setTimeout(()=>{
								that.$router.push({
									path:"/courseList"
								})
							},3000)
						}else if(type=="LearningRecords"){
							this.isRecords = true;
							this.btnChose = "records";
							this.menuChose = "";
							setTimeout(()=>{
								that.$router.push({
									path: "/LearningRecords"
								});
							},3000);
						}else{
							this.isRecords = false;
							this.btnChose = "sign";
							this.menuChose = type;
							setTimeout(()=>{
								that.$router.push({
									path: "/" + type
								});
							},3000);
						}
					}	
					setTimeout(function(){
						for (var i = 1; i < 99999; i++) {
							clearTimeout(i); //去除延时器
						}
					},4000);
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//关闭按钮
			closeScore(){
				this.$router.go(0)
			},
			//再来一次
			onceMore(){
				var that = this;
				if(this.examData.shengyu > 0){
					this.scoreVisible = false;
					this.$router.go(0)
				}else{
					this.$message({
						message: "您已没有考试次数",
						type: 'warning',
						showClose: true
					});
					setTimeout(function(){
						that.$router.go(0)
					},500);
				}
			},
			//查看答卷
			answerSheet(){
				this.scoreVisible = false;
				this.answerVisible = true;
			},
		},
		created() {
			//刷新默认选中菜单
			var cuurPath = this.$route.path;
			if(cuurPath!="/LearningRecords"){
				this.menuChose = cuurPath.substr(1);
				this.btnChose = "sign";
				this.isRecords = false;
			}else{
				this.menuChose = "";
				this.btnChose = "records";
				this.isRecords = true;
			}
			if(window.sessionStorage.getItem('userinfo')){
				this.userinfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo')))
			}
			if(this.$cookies.get("cityName")){
				this.cityName = this.$cookies.get("cityName")
			}
		},
		mounted () {
			Bus.$on('bus_menuChose', (val) => { //Hub接收事件(是否为省平台)
				this.menuChose = val;
				this.isRecords = false;
				this.btnChose = "sign" ;
			});			
		}
	}
</script>
<style scoped="scoped">
	.studentCon{
		width: 100%;
		height: 266px;
		background-color: #FFF;
		box-shadow: 1px 1px 6px 0px rgba(153,153,153,0.18);
		padding-top: 20px;
		text-align: center;
	}
	.studentImg{
		width: 50px;
		height: 50px;
		border-radius: 25px;
	}
	.studentName{
		color: #333;
		font-size: 18px;
		font-weight: bold;
		margin-top: 14px;
		margin-bottom: 7px;
	}
	.websiteName{
		font-size: 14px;
		color: #999;
		padding-bottom: 5px;
	}
</style>
<style>
	.LearningCenter{
		width: 100%;
		min-width: 1200px;
	}
	.LC_content{
		min-height: calc(100vh - 324px);
		background-color: #F4F5F9;
		padding-top: 32px;
		padding-bottom: 82px;
	}
	.LC_container{
		width: 1200px;
		margin: 0 auto;
		padding-left: 300px;
		min-height: 706px;
	}
	.LC_container::after{
		content: "";
		display: block;
		clear: both;
	}
	.LC_left{
		width: 260px;
		float: left;
		margin-left: -300px;
	}
	.LC_right{
		width: 100%;
		min-height: 706px;
		background-color: #FFF;
		box-shadow: 1px 1px 6px 0px rgba(153,153,153,0.18); 
	}
	.LC_right.isRecords{
		background-color: transparent;
		box-shadow: none; 
	}
	.loginBtn{
		margin-top: 23px;
	}
	.studentBtn{
		width: 190px;
		height: 34px;
		border-radius: 18px;
		font-size: 16px;
		line-height: 32px;
		margin: 16px auto 0;
		cursor: pointer;
		border: 1px solid #999999;
		color: #999;
	}
	.studentBtn.active{
		border:none;
		background: linear-gradient(72deg,#4c6df1 0%, rgba(76,109,241,0.90) 100%);
		box-shadow: 2px 3px 7px 0px rgba(77,109,241,0.34); 
		color: #FFF;
		line-height: 34px;
	}
	.studentBtn:hover{
		border: 1px solid #333;
		color: #333;
	}
	.studentBtn.active:hover{
		opacity: 0.8;
		border:none;
		color: #FFF;
	}
	.C_menu{
		width: 100%;
		height: 420px;
		background-color: #FFF;
		box-shadow: 1px 1px 6px 0px rgba(153,153,153,0.18); 
		margin-top: 20px;
	}
	.C_menu_item{
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 0;
		position: relative;
		cursor: pointer;
	}
	.C_menu_item img{
		vertical-align: middle;
		margin-right: 14px;
	}
	.C_menu_item span{
		vertical-align: middle;
		font-size: 16px;
		color: #333;
	}
	.C_menu_item.active{
		background-color: #F7F8FE;
	}
	.C_menu_item.active::after{
		width: 4px;
		height: 100%;
		background-color: #5E7BF2;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
	.LearningCenter .LC_box{
		width: 100%;
		/* height: 100%;
		position: relative; 
		padding-top: 60px;*/
	}
	.LearningCenter .LC_Header{
		width: 100%;
		height: 60px;
		/* position: absolute;
		top: 0;
		left: 0; */
		padding-left: 35px;
		line-height: 60px;
		font-size: 16px;
		color: #333;
		border-bottom: 1px solid #E6E7EB;
		font-weight: bold;
		position: relative;
	}
</style>
